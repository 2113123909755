
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class CardBurnedSuccess extends Vue {
  get card(): any | null {
    setTimeout(() => { this.stopConfetti(); }, 7000);
    this.startConfetti();
    return this.$store.getters['cardModule/card'];
  }

  mounted(): void {
    if (!this.card) {
      this.$router.push('/home');
    }
  }

  public stopConfetti(): any {
    this.$confetti.stop();
  }

  formatDate(date: string): string {
    return moment(date).format('DD/MM/YYYY');
  }

  public startConfetti(): any {
    this.$confetti.start(
      {
        particles:
          [
            {
              type: 'heart',
            },
          ],
      },
    );
  }
}

