
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CardBurnedFailure extends Vue {
  openContactMail(): void {
    const to = process.env.VUE_APP_MAIL_CONTACT;
    window.location.href = `mailto:${to}?subject=Problème%20carte%20CKDO`;
  }

  get error(): string {
    return this.$store.getters['card/error'];
  }
}
