
import { Component, Vue } from 'vue-property-decorator';
import CardBurnedSuccess from '@/components/CardBurnedSuccess.vue';
import CardBurnedFailure from '@/components/CardBurnedFailure.vue';

@Component({
  components: {
    CardBurnedSuccess,
    CardBurnedFailure,
  },
})
export default class CardBurnResult extends Vue {
  public result: string | undefined = undefined;

  created(): void {
    console.log(`Result: ${this.$route.query.result}`);
    this.result = String(this.$route.query.result);
  }
}
